<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Apiaries </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4>
      <v-flex xs3 xl2 text-left>
        <span class="itemValue">Apiary ID</span>
      </v-flex>
      <v-flex xs3 xl3 text-left>
        <span class="itemValue">Hive Count</span>
      </v-flex>
      <v-flex xs6 xl7 text-left>
        <span class="itemValue">Location</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-for="(item, i) in storage" :key="i">
      <v-flex xs3 xl2 text-left>
        <span class="itemKey">{{ item.apiaryid }}</span>
      </v-flex>
      <v-flex xs3 xl3 text-left>
        <span class="itemKey">{{ item.colonycount }}</span>
      </v-flex>
      <v-flex xs6 xl7 text-left >
        <span class="itemKey">
          {{ item.locationname.slice(0,25) }}
          <span v-if="item.locationname.length>25">...</span>
          <span v-if="!item.locationname">-</span>
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 pr-4 text-right>
        <router-link to="/Apiary">
          <span
            style="font-family: poppinsregular; font-size: 13px; color: #0073CF"
          >
            View More
          </span>
          <span class="itemArrow" style="color: #0073CF">>></span>
        </router-link>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
};
</script>