<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-start>
      <v-flex xs12 sm6 lg3 xl3 text-left>
        <v-card rounded="lg" elevation="12">
          <v-layout wrap justify-end pa-4 class="bg1">
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue">
                    {{ storage.supervisorcount }}
                  </span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Supervisors">
                    <span class="analyticsKey"> Supervisors </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/icons/users.png"
                height="50px"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-sm-4 pt-4 pt-sm-0 text-left>
        <v-card rounded="lg" elevation="12">
          <v-layout wrap justify-end pa-4 class="bg2">
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue">
                    {{ storage.apiarycount }}
                  </span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Apiary">
                    <span class="analyticsKey"> Total Apiaries </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/icons/apiary.png"
                height="50px"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-lg-4 text-left pt-4 pt-lg-0>
        <v-card rounded="lg" elevation="12">
          <v-layout wrap justify-end pa-4 class="bg3">
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue">
                    {{ storage.colonycount }}
                  </span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Hives">
                    <span class="analyticsKey"> Total Hives </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/icons/beehive.png"
                height="50px"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 xl3 pl-0 pl-sm-4 text-left pt-4 pt-lg-0>
        <v-card rounded="lg" elevation="12">
          <v-layout wrap justify-end pa-4 class="bg4">
            <v-flex xs9 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{ storage.eventcount }} </span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/Events">
                    <span class="analyticsKey"> Total Events </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 text-center align-self-center>
              <v-img
                src="./../../../assets/icons/events.png"
                height="50px"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import store from "./../../../store";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
    };
  },
  computed: {
    appType() {
      return store.state.userType;
    },
  },
};
</script>
<style scoped>
.analyticsKey {
  font-family: poppinsregular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 1px;
}
.analyticsValue {
  font-family: poppinsmedium;
  font-size: 25px;
  color: #ffffff;
}
.bg1 {
  background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
}
.bg2 {
  background-image: linear-gradient(to right, #f093fb 0%, #f5576c 100%);
}
.bg3 {
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}
.bg4 {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}
</style>